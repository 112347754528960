import { SyncedUser } from "@speakap/types";
import { ThunkAction } from "redux-thunk";
import { get } from "lodash";
import { notification } from "antd";

import { ApplicationState } from "../reducer";
import { getSyncedUsers } from "../api";
import { selectSyncedUsersNetworkId } from "./selectors";

export const FETCH_SYNCED_USERS_REQUEST = "FETCH_SYNCED_USERS_REQUEST";
export const FETCH_SYNCED_USERS_SUCCESS = "FETCH_SYNCED_USERS_SUCCESS";
export const FETCH_SYNCED_USERS_ERROR = "FETCH_SYNCED_USERS_ERROR";

interface FetchSyncedUserssRequestAction {
    type: typeof FETCH_SYNCED_USERS_REQUEST;
    payload: string;
}

interface FetchSyncedUserssSuccessAction {
    type: typeof FETCH_SYNCED_USERS_SUCCESS;
    payload: Array<SyncedUser>;
}

interface FetchSyncedUserssErrorAction {
    type: typeof FETCH_SYNCED_USERS_ERROR;
}

export type SyncedUsersActions =
    | FetchSyncedUserssSuccessAction
    | FetchSyncedUserssRequestAction
    | FetchSyncedUserssErrorAction;

type Thunk = ThunkAction<Promise<void>, ApplicationState, void, SyncedUsersActions>;

export const fetchSyncedUsers = (networkId: string): Thunk => async (dispatch, getState) => {
    try {
        const state = getState();
        const currentNetworkId = selectSyncedUsersNetworkId(state);
        if (currentNetworkId !== networkId) {
            dispatch({ payload: networkId, type: FETCH_SYNCED_USERS_REQUEST });
            const response = await getSyncedUsers(networkId);
            dispatch({
                payload: response,
                type: FETCH_SYNCED_USERS_SUCCESS,
            });
        }
    } catch (error) {
        const message = get(
            error,
            "response.data",
            error instanceof Error ? error.message : "Error",
        );
        dispatch({
            message: error instanceof Error ? error.message : "Error",
            type: FETCH_SYNCED_USERS_ERROR,
        });
        notification.error({
            message,
        });
    }
};
