import { SyncedUser } from "@speakap/types";

import { LOADING_STATES } from "../../types";
import {
    SyncedUsersActions,
    FETCH_SYNCED_USERS_ERROR,
    FETCH_SYNCED_USERS_REQUEST,
    FETCH_SYNCED_USERS_SUCCESS,
} from "./actions";

export interface SyncedUsersState {
    loadingState: LOADING_STATES;
    networkId: string;
    syncedUsers: Array<SyncedUser>;
}

export const initialState: SyncedUsersState = {
    loadingState: LOADING_STATES.INITIAL,
    networkId: "",
    syncedUsers: [],
};

const networksReducer = (
    state: SyncedUsersState = initialState,
    action: SyncedUsersActions,
): SyncedUsersState => {
    switch (action.type) {
        case FETCH_SYNCED_USERS_REQUEST:
            return {
                ...initialState,
                loadingState: LOADING_STATES.FETCHING,
                networkId: action.payload,
            };
        case FETCH_SYNCED_USERS_SUCCESS:
            return {
                loadingState: LOADING_STATES.SUCCESSFUL,
                networkId: state.networkId,
                syncedUsers: action.payload,
            };
        case FETCH_SYNCED_USERS_ERROR:
            return {
                loadingState: LOADING_STATES.ERROR,
                networkId: state.networkId,
                syncedUsers: [],
            };
        default:
            return state;
    }
};
export default networksReducer;
