import { SyncedUser, SyncedUserHistory } from "@speakap/types";

import axios from "./request";

export const getSyncedUsers = (networkId: string): Promise<Array<SyncedUser>> =>
    axios.get(`/networks/${networkId}/synced-users`).then(({ data }) => data.syncedUsers);

interface GetSyncHistoryParams {
    networkId: string;
    syncedUserId: number;
}

export const getSyncHistory = ({
    networkId,
    syncedUserId,
}: GetSyncHistoryParams): Promise<Array<SyncedUserHistory>> =>
    axios
        .get(`/networks/${networkId}/synced-users/${syncedUserId}/sync-history`)
        .then(({ data }) => data.syncHistory);
